.omnibox-search {
    border: 1px solid rgba(0,0,0,0.2);
    width: 100%;
    display: flex;
    overflow: visible;
    padding: 5px 10px;
    border-radius: 3px;
}

.omnibox-search .item {
    margin-right: 0.5em;
    white-space: nowrap;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 1px 2px;
    border-radius: 3px;
}

.omnibox-search .filters {
    display: flex;
    overflow-x: auto;
    padding-bottom: 4px;
    margin-right: 10px;
}


.omnibox-search .search-box {
    display: flex;
    flex-grow: 1;
    min-width: 400px;
    position: relative;
    overflow: visible;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 2px 5px;
    border-radius: 3px 0 0 3px;
}

.omnibox-search .search-btn {
    border-radius: 0 3px 3px;
}

.omnibox-search .searchmenu {
    background: white;
}

.omnibox-search .search-box input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-left: 3px;
}

.omnibox-search .search-box input:focus {
    border-bottom: 1px solid rgba(0,0,0,0.4);
}

.omnibox-search .search-box .searchmenu {
    position: absolute;
    top: 25px;
    max-height: 400px;
    overflow: auto;
    padding: 5px 0;
    border: 1px solid rgba(0,0,0,0.2);
    min-width: 250px;
    border-radius: 5px;
    z-index: 1;
    display: none
}

.omnibox-search .search-box .searchmenu.open {
    display: block
}
.omnibox-search .search-box .searchmenu .menuitem {
    display: block;
    cursor: pointer;
    padding: 2px 5px;
}

.omnibox-search .search-box .searchmenu .menuitem.active {
    background: rgba(0,0,0,0.2);
}
.omnibox-search .search-box .searchmenu .menuitem:hover {
    background-color: rgba(0,0,0,0.2);
}

.omnibox-search .filtered-input {
    border-radius: 5px;
    flex-grow: 1;
    position: relative;
}

.omnibox-search .filtered-input input:focus + .searchmenu {
    display: block
}

.omnibox-search .filter {
    vertical-align: middle;
    padding: 2px 5px;
    background-color: rgba(0,0,0,0.3);
}

.omnibox-search .filter:empty {
    visibility: hidden;
}