.order-container {
    max-width: 1500px;
    padding-top: 1em;
}

.no-entry {
    height: 200px;
    border: 3px solid #797979;; 
    border-radius: 15px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #797979;
    flex-direction: column;
}

.no-entry .btn {
    margin-top: 5px;
}

.locationButtonGroup {
    max-height: 48px;
}

.reorder {
    cursor: pointer;
    padding: 0 6px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s;
}

.reorder:hover {
    background-color: #006de1;
}

.reorder.disabled {
    background-color: #58a8ff;
    cursor: auto;
}