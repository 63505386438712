.listview .selected {
    border: solid 1px black;
    border-radius: 3px;
    display:flex;
    flex-wrap: wrap;
    margin: 5px 0;
}

.listview .selected .item {
    margin-top: 2px;
    margin-right: 3px;
    padding: 3px 5px;
    background: rgba(0,0,0,0.1);
}

.dragged_over {
    background-color: #aaa;
}

.selection-length {
    display: flex;
    margin-left: auto;
    margin-right: 1em;
    align-items: center;
    font-style: italic;
}