.label-manager {
    z-index: 1;
}

.colored-dot {
    margin-right: 5px;
    line-height: 10px;
    font-size: 10px;
    border-radius: 100px;
}

.colored-dot::before {
    content: "\00A0";
    height: 5px;
    margin: 5px;
    display: inline-block;
    box-sizing: border-box;
}